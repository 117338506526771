.running-total {

  &__section-header-wealth-transfer {
    display: flex;
    justify-content: space-between;
    height: 48px;
    line-height: 48px;
    padding-right: $spacing-2xl;
    padding-left: 39px;

    .header-column {
      font-weight: 500;
    }

    .currency {
      color: $color-nt-grey-550;
    }
  }

  &__section-header-wpo {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 16px;
    grid-template-columns: repeat(6, 1fr);

    height: 48px;
    line-height: 48px;
    padding-right: $spacing-2xl;
    padding-left: 39px;

    .header-column {
      font-weight: 500;
      grid-column: 1 / span 3;
    }

    .currency {
      color: $color-nt-grey-550;
      grid-column: 4 / span 1;
      text-align: right;
    }

    .currency-span-2 {
      color: $color-nt-grey-550;
      grid-column: 5 / span 1;
      text-align: right;
    }
  }

  &__section {
    padding-right: $spacing-2xl;
    padding-left: $spacing-md + $spacing-xxl;
    padding-bottom: 10px;
    background-color: $color-nt-grey-050;

    .line-item-row-wealth-transfer {
      display: flex;
      justify-content: space-between;
      line-height: $spacing-xxl;
      margin-left: 12px;
    }

    .line-item-row-wpo {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 16px;
      grid-template-columns: repeat(6, 1fr);

      line-height: $spacing-xxl;

      .span-1 {
        padding-left: 24px;
        grid-column: 1 / span 3;
      }

      .span-2 {
        grid-column: 4 / span 1;
        text-align: right;
      }

      .span-3 {
        grid-column: 5 / span 1;
        text-align: right;
      }
    }

    .subsection-header {
      padding-top: 10px;
      border-top: 1px solid #B1B1B1;
      font-weight: 500;
    }
  }
}