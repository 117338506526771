.asset-reliance-header-container {
  .btn-skin--borderless:not(.btn__icon-only) {
    color: white;
  }

  .btn-skin--borderless {
    &:hover {
      background-color: transparent !important;
    }
  }

  .asset-reliance-page-action-menu-options {
    display: flex;
    justify-content: space-between;

    input {
      accent-color: #3D4042;
    }

    .show-fve-options {
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      width: 100%;
    }
  }

  .three-dots-menu {
    .icon {
      font-size: 18px;
      font-weight: 400;
      color: white;
      padding-right: 0px;

      &:hover {
        color: $color-aqua-800;
        box-shadow: none;
      }
    }
  }

  .three-dots-menu-report {
    .icon {
      font-size: 18px;
      font-weight: 400;
      color: black;
      padding-right: 0px;

      &:hover {
        color: white;
        box-shadow: none;
      }
    }
  }
}