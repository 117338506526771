.strategy-summary-details-section {
  padding: 24px 24px 0 24px;

  .summary-details-tab {
    min-height: 241px;
    max-width: 100% !important;
    width: 100%;
    height: 100%;

    background: $color-nt-container-background;
    border: 1px solid $color-nt-grey-100;
    border-radius: 5px;
    padding: 24px 32px 40px 32px !important;

    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
    grid-template-rows: 40px minmax(149px, 100%);
    grid-gap: 24px;

    .summary-details-card {
      width: 100%;
      display: grid;
      grid-auto-flow: row;
      grid-auto-rows: 1fr;
      grid-template-rows: 75px minmax(149px, 100%);
      padding: 0 !important;

      .accented-accordion-item {
        margin-bottom: 0;

        &:first-child {
          .accented-accordion-item__accent {
            border-bottom-left-radius: 0;
          }
        }

        &:not(:first-child) {
          .accented-accordion-item__accent {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }
        }

        &:last-child {
          .accented-accordion-item__accent {
            border-top-left-radius: 0;
            border-bottom-left-radius: 5px;
          }
        }

        &:only-child {
          .accented-accordion-item__accent {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }
      }

      h4 {
        margin: 0 !important;
        padding-top: 20px;
        height: 10px;
      }

      .strategy-list-header {
        display: grid;
        grid-auto-flow: column;
        align-items: end;
        grid-gap: 16px;
        grid-template-columns: repeat(7, 1fr);
        padding: 0 32px 8px 43px !important;

        span {
          font-family: RobotoCondensed, Roboto, sans-serif;
          color: $color-nt-grey-700;
          line-height: 15px;
          font-size: 13px;
          font-weight: 400;
          font-stretch: condensed;
          text-transform: uppercase;
        }

        .span-1 {
          grid-column: 1 / span 2;
        }

        .span-2 {
          grid-column: 3 / span 3;
        }

        .span-3 {
          grid-column: 6 / span 1;
        }

        .span-4 {
          grid-column: 7 / span 1;
          margin-left: 30%
        }
      }

      .strategy-accordion-item {
        &__report {
          display: grid;
          grid-auto-flow: column;
          padding: 0 32px 8px 44px !important;
          grid-gap: 16px;
          grid-template-columns: repeat(7, 1fr);
          height: 58px;
          align-items: center;
          background: white;

          span {
            color: $color-nt-grey-700;
            line-height: 20px;
            font-size: 15px;
            font-weight: 400;
          }

          .span-1-enabled {
            grid-column: 1 / span 2;
          }

          .span-1-disabled {
            grid-column: 1 / span 2;
            color: $color-nt-grey-300;
          }

          .span-2-enabled {
            grid-column: 3 / span 2;
          }

          .span-2-disabled {
            grid-column: 3 / span 2;
            color: $color-nt-grey-300;
          }

          .span-3-enabled {
            grid-column: 6 / span 1;
          }

          .span-3-disabled {
            grid-column: 6 / span 1;
            color: $color-nt-grey-300;
          }

          .span-4-enabled {
            grid-column: 7 / span 1;
          }

          .span-4-disabled {
            grid-column: 7 / span 1;
            color: $color-nt-grey-300;
          }
        }

        &__web {
          display: grid;
          grid-auto-flow: column;
          padding: 0 0 0 32px !important;
          grid-gap: 16px;
          grid-template-columns: repeat(7, 1fr);
          height: 58px;
          align-items: center;
          background: white;

          span {
            color: $color-nt-grey-700;
            line-height: 20px;
            font-size: 15px;
            font-weight: 400;
          }

          .span-1-enabled {
            grid-column: 1 / span 2;
          }

          .span-1-disabled {
            grid-column: 1 / span 2;
            color: $color-nt-grey-300;
          }

          .span-2-enabled {
            grid-column: 3 / span 2;
          }

          .span-2-disabled {
            grid-column: 3 / span 2;
            color: $color-nt-grey-300;
          }

          .span-3-enabled {
            grid-column: 6 / span 1;
          }

          .span-3-disabled {
            grid-column: 6 / span 1;
            color: $color-nt-grey-300;
          }

          .span-4-enabled {
            grid-column: 7 / span 1;
          }

          .span-4-disabled {
            grid-column: 7 / span 1;
            color: $color-nt-grey-300;
          }
        }
      }

      .delete-trash-icon {
        margin-left: auto;
      }

      .strategy-list-totals {
        display: grid;
        grid-auto-flow: column;
        padding: 8px 32px 0 43px !important;
        grid-gap: 16px;
        grid-template-columns: repeat(7, 1fr);

        span {
          text-align: right;
          font-weight: 500;
        }

        .span-1 {
          grid-column: 1 / span 5;
        }

        .span-2 {
          grid-column: 6 / span 1;
        }

        .span-3 {
          grid-column: 7 / span 1;
        }
      }
    }

    .est-estate-tax-card {
      width: 100%;
      display: grid;
      padding: 0 !important;

      .accordion-header-content {
        padding: 0 48px 8px 50px;

        span {
          font-family: RobotoCondensed, Roboto, sans-serif;
          color: $color-nt-grey-700;
          line-height: 15px;
          font-size: 13px;
          font-weight: 400;
          font-stretch: condensed;
          text-transform: uppercase;
        }
      }

      .accordion-item-header-content {
        padding: 0 48px 0 0;
        height: 64px;
        align-items: center;

        span {
          font-family: RobotoCondensed, Roboto, sans-serif;
          color: $color-nt-grey-700;
          line-height: 15px;
          font-size: 13px;
          font-weight: 400;
          font-stretch: condensed;
          text-transform: uppercase;
        }
      }

      .estate-tax-breakdown-header {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 16px;
        grid-template-columns: repeat(6, 1fr);
        width: 100%;

        background-color: white;

        h4 {
          margin: 0 !important;
          font-weight: 400;
        }

        .span-1 {
          grid-column: 1 / span 3;
          align-items: center;
        }

        .span-2 {
          grid-column: 4 / span 1;
          text-align: right;
        }

        .span-3 {
          grid-column: 5 / span 1;
          text-align: right;
        }
      }

      .estate-tax-breakdown-header-row {
        display: flex;
        flex-flow: row;
        background-color: white;

        .icon {
          width: 40px;
          align-content: center;
          padding: 0 8px 0 8px;
        }
      }
    }

    .estate-tax-breakdown-accordion-header {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 16px;
      grid-template-columns: repeat(6, 1fr);
      padding: 0 48px 8px 48px;
      text-align: right;

      span {
        font-family: RobotoCondensed, Roboto, sans-serif;
        color: $color-nt-grey-700;
        line-height: 15px;
        font-size: 13px;
        font-weight: 400;
        font-stretch: condensed;
        text-transform: uppercase;
      }

      .span-1 {
        grid-column: 1 / span 3;
      }

      .span-2 {
        grid-column: 4 / span 1;
      }

      .span-3 {
        grid-column: 5 / span 1;
      }
    }
  }
}